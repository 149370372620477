export default function Version(){
  

    const dateFormat = (dateTime) => {
        const t = new Date(dateTime);
        const dayBefore = t.getDate();
        const day = dayBefore>=10?dayBefore:'0'+dayBefore;
        const hoursBefore = t.getHours();
        const hours = hoursBefore >= 10?hoursBefore:'0'+hoursBefore;
        const minutesBefore = t.getMinutes();
        const minutes = minutesBefore >=10 ?minutesBefore : '0' +minutesBefore;
        return day.toString()+hours.toString()+minutes.toString();
        
    }

    return<div className="version"><span>V0.4.2.{'e7e7bd22f66e3cfa9d41b3380cdbe91f06b5a447'?.slice(0,7)}</span>  </div>

}